import React, {useState, useEffect} from 'react'
import { Layout, Navbar, BigMenu } from '../components'

const NotFoundPage = () => {
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(()=>{
      setHasMounted(true)
  },[])
  if(!hasMounted)return null
  return(
    <Layout>
      <Navbar/>
      <BigMenu/>
      <div style={{height:"600px"}}><h1 style={{textAlign:"center"}}>Oops that page does not exist</h1></div>
    </Layout>
  )
}

export default NotFoundPage
